import { DateTime } from "luxon";
import { getName } from "otto_enum";
import { h } from 'preact';
import { Days } from "../../shared";
import { devLog } from "../../utils/log";

export function getDT(val, tz?) {
    if (val) {
        let opts = undefined;
        // let opts = { zone: tz };
        var dt: DateTime;
        if (val.constructor.name == 'Number') {
            dt = DateTime.fromMillis(val, opts);
        }
        else if (val.constructor.name == "String") {
            dt = DateTime.fromISO(val, opts);
        } else if (val.constructor.name == "Date") {
            dt = DateTime.fromJSDate(val, opts);
        } else if (val instanceof DateTime) {
            dt = val;
        }
        if (tz) {
            // devLog('dt.zone.name', dt.zone.name, dt.zoneName);
            if (dt.zoneName != tz) {
                dt = dt.setZone(tz);
            }
            // devLog('Time:::::;ADFTERTZ', tz, dt.toString());
            // devLog('dt.zone.name', dt.zone.name, dt.zoneName);
        }
        return dt
    }

}
function formatPhoneNumber(s) {
    // console.info('handle formatting beetter.')
    var s2 = ("" + s).replace(/\D/g, '');
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
}
export function dateWithDayOfWeek(val, tz) {
    if (!val) return null;
    return getDT(val, tz).toFormat('EEEE MM/dd/yyyy');
}
export function timeWithDayOfWeek(val, tz) {
    if (!val) return null;
    return getDT(val, tz).toFormat('EEEE @ hh:mm a');
}
export function date(val: Date | string | number) {
    if (!val) return null;
    return getDT(val).toFormat('MM/dd/yyyy');
}
export function datetimeWithBar(val, tz) {
    if (!val) return null;
    return getDT(val, tz).toFormat('MMM dd | hh:mm a');
}
export function datetimeWithAt(val, tz) {
    if (!val) return null;
    return getDT(val, tz).toFormat('MMMM dd @ hh:mma');
}
export function datetime(val, tz) {
    if (!val) return null;
    return getDT(val, tz).toFormat('MM/dd/yyyy hh:mm a');
}
export function time(val, tz?) {
    if (!val) return null;
    let dt = getDT(val, tz);
    // devLog('Time:::::;', dt.toString());

    return dt.toFormat('hh:mm a');
}
export function dateMonth(val: Date | string | number) {
    if (!val) return null;
    return getDT(val).toFormat('MM/dd');
}
export function phone(str) {
    if (!str) {
        return null;
    }
    return <span><a href={`tel:${str}`}>{formatPhoneNumber(str)}</a></span>
}
export function phoneNoLink(str) {
    return formatPhoneNumber(str)
}
export function email(str) {
    return <span><a href={`mailto:${str}`}>{str}</a></span>
}
export function usd(val) {
    let c = 2, d = '.', t = ',';
    c = isNaN(c = Math.abs(c)) ? 2 : c;
    d = d == undefined ? "." : d;
    t = t == undefined ? "," : t;
    let s = val < 0 ? "-" : "";
    let i = parseInt(val = Math.abs(+val || 0).toFixed(c)) + "";
    let iNum = Number(i);
    let j;
    j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "")
        + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t)
        + (c ? d + Math.abs(val - iNum).toFixed(c).slice(2) : "");
}
export function upload(upload, url) {
    if (upload.mimetype.indexOf('image') > -1) {
        return <img width={150} src={`${url}/v/${upload._id}`} title={upload.originalname} />
    }
    if (upload.mimetype.indexOf('application/pdf') > -1) {
        return <embed src={`${url}/v/${upload._id}`} title={upload.originalname} />
    }

    return <a target="_blank" href={`${url}/d/${upload._id}`}>Download</a>

}
export function percent(v: number) {
    if (!v) {
        return null;
    }
    return v.toFixed(2) + '%';
}
export function createDateFromNumber(num: number, tz = null) {
    let dt = DateTime.fromFormat(`${num < 1000 ? '0' : ''}${num}`, 'HHmm', { zone: tz });
    // if (tz) {
    //     devLog('TZ', tz, dt)
    //     dt = dt.setZone(tz);
    // }
    if (dt.minute == 50) {
        dt = dt.set({ minute: 30 });
    }
    // devLog('createDateFromNumber', num, dt.toString());
    if (dt) {
        devLog('createDateFromNumber', tz, dt.zoneName, dt.toJSDate())
        return dt.toJSDate();
    }
    return null;
}
export function getDayName(day) {
    return getName(Days, day);
}
export default { dateWithDayOfWeek, timeWithDayOfWeek, _d: date, usd, _dt: datetime, _dm: dateMonth, _t: time, phone, email, upload, percent }